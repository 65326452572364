/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import HomeTemplate from 'src/templates/home'

function HomePage({ data }) {
  return (
    <HomeTemplate data={data} />
  )
}

export default HomePage

export const query = graphql`
	query ENNativeHomeTemplate {
		wordpressPage(wordpress_id: { eq: 16 }) {
			...HomeFrag
		}

		allWordpressWpCampagnes(filter: {wpml_current_locale: {eq: "en_US"}}) {
			totalCount
		}

		allWordpressWpMerken(filter: {wpml_current_locale: {eq: "en_US"}}) {
			...MerkenFrag
    }
	}
`
